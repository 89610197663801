import { FC, useId } from 'react';

import { Placeholder, wrap } from '@sitecore/common';
import { ContentHubFilterRendering } from '@sitecore/types/manual/ContentHubFilter';
import { Box, Grid, Heading, PageGrid, Stack, Stretch, Text } from '@sparky';

import { ArticleList } from './components/ArticleList';
import { TagNavigation } from './components/TagNavigation';
import useContentFilter from './useContentFilter';

const ContentHubFilter: FC<ContentHubFilterRendering> = ({ fields }) => {
  const { title, tagAriaLabel, singleArticleFoundLabel, noArticlesFoundLabel, multipleArticlesFoundLabel } = fields;
  const articleContainerId = useId();
  const { tags, filteredArticles, articleCount } = useContentFilter(fields.articles, fields.tags);

  const formatArticleCount = () => {
    switch (articleCount) {
      case 0:
        return wrap(noArticlesFoundLabel);
      case 1:
        return wrap(singleArticleFoundLabel);
      default:
        return wrap(
          multipleArticlesFoundLabel,
          multipleArticlesFoundLabel.value?.replace('{articleCount}', articleCount.toString()),
        );
    }
  };

  return (
    <Box paddingY="10">
      <PageGrid>
        <PageGrid.Item gridColumn="1/-1">
          <Stack gap="10" alignX={{ initial: 'start', md: 'center' }}>
            {(title?.value || title?.editable) && <Heading as="h2">{wrap(title)}</Heading>}
            <TagNavigation tags={tags} tagAriaLabel={tagAriaLabel.value} articleContainerId={articleContainerId} />
            <Text size="BodyXL">{formatArticleCount()}</Text>
            <Stretch width={true} height={'auto'}>
              <Grid columns={{ initial: 9, md: 12 }}>
                <Grid.Item gridColumn={{ initial: '1 / -1', md: '1 / -4' }}>
                  <Placeholder name="jss-content-hub-filter-introduction" />
                </Grid.Item>
              </Grid>
            </Stretch>
            <ArticleList articles={filteredArticles} id={articleContainerId} />
          </Stack>
        </PageGrid.Item>
      </PageGrid>
    </Box>
  );
};

export default ContentHubFilter;
