import { FC } from 'react';

import { useLinkComponent } from '@link';
import { mapImage } from '@sitecore/common';
import { ContentHubFilterRendering } from '@sitecore/types/manual/ContentHubFilter';
import { Card, Heading, Image, Box, TextLink, Stack, Text, Stretch } from '@sparky';
type Props = {
  article: ContentHubFilterRendering['fields']['articles'][0];
};

export const Article: FC<Props> = ({ article }) => {
  const Link = useLinkComponent();
  const { title, content, pageLinkText, image } = article.fields;
  const { src, alt, sources } = mapImage(image);
  return (
    <Card overflow="hidden">
      <Stretch>
        <Stack>
          {src ? (
            <Image width="100%" aspectRatio="2 / 1" objectFit="cover" src={src} sources={sources} alt={alt} />
          ) : null}
          <Stack.Item grow>
            <Stretch>
              <Box padding="6">
                <Stretch>
                  <Stack gap="6" alignY="justify">
                    <Stack gap="6" alignY="justify">
                      <Heading as="h3" size="S">
                        {title.value}
                      </Heading>
                      {content.value ? <Text>{content.value}</Text> : null}
                    </Stack>
                    <Link linkType="internal" href={article.url}>
                      <TextLink emphasis="high">{pageLinkText.value}</TextLink>
                    </Link>
                  </Stack>
                </Stretch>
              </Box>
            </Stretch>
          </Stack.Item>
        </Stack>
      </Stretch>
    </Card>
  );
};
