import { FC, useEffect, useState } from 'react';

import { Stack, Tag, TextLink } from '@sparky';
import { useI18nTranslation } from '@sparky/providers';

import { Tag as TagType } from '../useContentFilter';

type Props = {
  tags: TagType[];
  tagAriaLabel: string;
  articleContainerId: string;
};

type ResetType = {
  id: string;
  displayText: string;
  selected: boolean;
  onClick: () => void;
};

export const TagNavigation: FC<Props> = ({ tags, tagAriaLabel, articleContainerId }) => {
  const [resetButtonSelected, setResetButtonSelected] = useState(true);

  useEffect(() => {
    const isAnyTagSelected = tags.some(tag => tag.selected && tag.id !== 'reset');
    setResetButtonSelected(!isAnyTagSelected);
  }, [tags]);

  const resetButton: ResetType = {
    id: 'reset',
    displayText: useI18nTranslation('resetFilters'),
    selected: resetButtonSelected,
    onClick: () => {
      tags.forEach(tag => {
        if (tag.selected) tag.onToggle();
      });

      setResetButtonSelected(true);
    },
  };

  return (
    <nav aria-label={tagAriaLabel}>
      <Stack alignX="start" direction="row" as="ul" gap={{ initial: 2, md: 4 }} wrap>
        {tags.map(tag => (
          <Tag key={tag.id} onClick={tag.onToggle} isCurrent={tag.selected} ariaControls={articleContainerId}>
            {tag.displayText}
          </Tag>
        ))}
        {!resetButton.selected && (
          <TextLink key={resetButton.id} onClick={resetButton.onClick}>
            {resetButton.displayText}
          </TextLink>
        )}
      </Stack>
    </nav>
  );
};
