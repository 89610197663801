import { useState, useMemo, useEffect } from 'react';

import { ContentHubFilterRendering } from '@sitecore/types/manual/ContentHubFilter';

export type Tag = {
  id: string;
  displayText: string;
  selected: boolean;
  onToggle: () => void;
};

const useContentFilter = (
  articles: ContentHubFilterRendering['fields']['articles'],
  tagList: ContentHubFilterRendering['fields']['tags'],
) => {
  const [filteredArticles, setFilteredArticles] = useState(articles);

  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    setTags(prevTags => {
      return tagList.map(tag => {
        return {
          id: tag.id,
          displayText: tag.displayName,
          selected: prevTags.find(prevTag => prevTag.id === tag.id)?.selected ?? false,
          onToggle: () => {
            updateTags(tag.id);
          },
        };
      });
    });
  }, [tagList]);

  const updateTags = (selectedtagId: string) => {
    setTags(prevtags => {
      const updatedtags = prevtags.map(prevtag => {
        if (prevtag.id === selectedtagId) {
          return {
            ...prevtag,
            selected: !prevtag.selected,
          };
        }
        return prevtag;
      });
      return updatedtags;
    });
  };

  const selectedTags = useMemo(() => tags.filter(tag => tag.selected).map(tag => tag.id), [tags]);

  useMemo(() => {
    const filteredArticles = selectedTags.length
      ? articles.filter(article => {
          return article.fields.tags.some(tag => selectedTags.includes(tag.id));
        })
      : articles;
    setFilteredArticles(filteredArticles);
  }, [articles, selectedTags]);

  const articleCount = filteredArticles.length;

  return { articleCount, filteredArticles, tags };
};

export default useContentFilter;
