import { FC } from 'react';

import { ContentHubFilterRendering } from '@sitecore/types/manual/ContentHubFilter';
import { Box, Grid, Stretch } from '@sparky';

import { Article } from './Article';

type Props = {
  articles: ContentHubFilterRendering['fields']['articles'];
  id: string;
};

export const ArticleList: FC<Props> = ({ articles, id }) => {
  return (
    <Stretch>
      <Box id={id}>
        <Grid as="ul" columns={{ initial: 1, md: 2, lg: 3 }} gap={{ initial: 6, md: 10, lg: 16 }}>
          {articles.map(article => (
            <Article key={article.id} article={article} />
          ))}
        </Grid>
      </Box>
    </Stretch>
  );
};
